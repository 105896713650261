.filter-dropdown {
    padding: 0;
    background: #ffffff;
    box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
    border-radius: 4px;
}

.filter-dropdown .ant-form-item {
    margin-bottom: 0;
}

.filter-dropdown .ant-space {
    width: 100%;
}

.filter-dropdown .filter-dropdown-header {
    padding: 10px;
    background: #f0f2f5;
    justify-content: space-between;
    width: 100%;
}

.filter-dropdown-container {
    padding: 10px;
    background: #f0f2f5;
}