@import "~antd/dist/antd.less";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.ant-menu-dark.ant-menu-inline .ant-menu-item.ant-menu-item-selected::before {
  border-left: 3px solid @menu-dark-selected-item-icon-color;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ant-menu-item.ant-menu-item-active {
  background-color: @primary-1;
}

.ant-table-thead > tr > th {
  border-bottom: 1px solid #707070;
}

body {
    font-family: Roboto, serif;
    --primary: @primary-color;
    --grey-50: #F8F8F8;
    --grey-100: #F3F3F3;
    --grey-200: #E5E5E5;
    --grey-300: #B1B1B1;
    --grey-400: #707070;
}

.ant-col {
  display: flex;
}


/**
 * Card Component
 */

.ant-card {
  flex: 1;
  border-radius: 4px;
}

.ant-card-meta {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.ant-card-meta-avatar {
  flex: 1;
  text-align: end;
}

.ant-card-meta-avatar .anticon {
  font-size: 40px;
}

.ant-card-meta-detail {
  flex: 3;
}

.ant-card-meta-title {
  font-size: 28px;
}

.chart-card {
  width: 100%;
}

.ant-card-meta-avatar {
  color: @primary-color
}

.card-header-options .ant-card-head .ant-card-head-wrapper {
  display: block
}
/**
 * End Card Component
 */

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #e1e4ed;
}

.spin-container {
  align-items: center;
  justify-content: center;
  display: flex;
}

.spin-container-full {
  width: 100%;
  height: 100%;
}

.spin-container-screen-size {
  width: 100vw;
  height: 100vh;
}

.attempts-menu-header {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.attempts-menu-item:hover .ant-typography {
  color: @primary-color;
}

.attempts-menu-item {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}

.ant-card-meta-title {
  white-space: normal;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}
@primary-color: @black;@primary-1: #E5E5E5;@layout-header-background: @black;@btn-default-ghost-color: white;@layout-body-background: #F3F3F3;@link-color: #00A3E0;@link-hover-color: #0087ba;@link-active-color: #0087ba;@success-color: #21D59B;@error-color: #F0142F;