.tabs-filters-container {
    display: flex;
    width: 100%;
    gap: 8px;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap-reverse
}

.tabs-filters-container .tabs-container {
    flex: 1;
}