.data-table-card {
    width: 100%;
    padding: 0;
}

.data-table-card .ant-card-body {
    padding: 0;
}

.data-table-card .ant-pagination {
    margin: 16px 16px;
}