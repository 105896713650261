.logo {
    padding: 30px 0 40px 0;
    text-align: center;
    width: 200px;
}

.site-layout .site-layout-background {
    background: #fff;
}

.header-container {
    padding: 0;
    height: 50px;
}

.header-background {
    background: #E5E5E5;
}

.header-container .ant-select-clear {
    background: #d5d7e3;
}

.top-filter-single-select .ant-select-selection-item {
    font-size: 24px;
}

.sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    gap: 16px;
}